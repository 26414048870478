import { MEMBER, OFFICER } from 'constants/roles.constant'

const appConfig = {
  backendURL: process.env.REACT_APP_API_URL,
  apiPrefix: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}`,
  authenticatedEntryPath: {
    [OFFICER]: '/lodges',
    [MEMBER]: '/getting-started',
  },
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: true,
}

export default appConfig
