import ApiService from './ApiService'
import { apiCreateDocument } from './DocumentService'

export async function apiGetAssignmentsList(params) {
  return await ApiService.fetchData({
    url: '/assignments',
    method: 'get',
    params,
  })
}

export async function apiGetAssignmentDetails(id) {
  const repsonse = await ApiService.fetchData({
    url: `/assignments/${id}/details/`,
    method: 'get',
  })

  return repsonse.data
}

export async function apiUpdateAssignment(id, data) {
  return await ApiService.fetchData({
    url: `/assignments/${id}`,
    method: 'put',
    data: { data },
  })
}

export async function apiUpdateAssignmentStatus(id, data) {
  return await ApiService.fetchData({
    url: `/assignments/${id}/status`,
    method: 'put',
    data: { data },
  })
}

export async function apiPutComment(id, data) {
  return await ApiService.fetchData({
    url: `/assignments/${id}/comments`,
    method: 'put',
    data: { data },
  })
}

export async function apiAddAssignmentDocuments({ id, data }) {
  const { files } = data
  const documents = []
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    documents.push(await apiCreateDocument(file))
  }

  const documentIDs = documents.map(({ data }) => data.data.id)
  return await ApiService.fetchData({
    url: `/assignments/${id}/documents`,
    method: 'put',
    data: { data: { documents: { connect: documentIDs } } },
  })
}

export async function apiPostComment(data) {
  const response = await ApiService.fetchData({
    url: `/comments`,
    method: 'post',
    data: { data },
    params: { populate: ['commenter.picture'] },
  })

  return response.data
}

export async function apiPutAssignment(data, params) {
  const response = await ApiService.fetchData({
    url: '/assignments',
    method: 'post',
    data: { data },
    params,
  })
  return response.data
}

export async function apiGetPreDefinedAssignments() {
  const response = await ApiService.fetchData({
    url: '/pre-defined-assignments',
    method: 'get',
  })
  return response.data
}
