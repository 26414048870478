import { Upload } from 'components/ui'

const allowedFileType = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+sml',
  'image/tiff',
  'image/webp',
]

const FileUploadArea = ({ onFileAdd, onFileRemove }) => {
  const beforeUpload = (file) => {
    let valid = true

    const maxFileSize = 4000000

    for (let f of file) {
      if (!allowedFileType.includes(f.type))
        valid = 'One or more files are invalid. Please only upload PDF or Word documents or images.'
      if (f.size >= maxFileSize) valid = 'One or more files are invalid. A document cannot be more than 4 MB.'
    }

    return valid
  }

  return (
    <Upload beforeUpload={beforeUpload} onChange={onFileAdd} onFileRemove={onFileRemove} showList draggable multiple>
      <div className="text-center">
        <p className="font-semibold">
          <span className="text-gray-800 dark:text-white">Drop your document here, or </span>
          <span className="text-blue-500">browse</span>
        </p>
        <p className="mt-1 opacity-60 dark:text-white">Supported: *.docx, *.doc, *.pdf, *.jpeg, *.png, *.gif, *.webp</p>
      </div>
    </Upload>
  )
}

export default FileUploadArea
