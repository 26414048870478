import ApiService from './ApiService'

export async function apiCreateDocument(file) {
  const data = new FormData()
  data.append('files.file', file, file.name)

  return ApiService.fetchData({
    url: '/documents',
    method: 'post',
    data,
  })
}
